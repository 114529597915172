.Error_Image {
    height: 200px;
    margin-top: 60px;
}
.Error_Text {
    font-family: Arial, Helvetica, sans-serif;
    margin-top: 40px;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.04);
    border-radius: .25rem;
  }
  
  .card .card-header {
    background-color: #fff;
    border-bottom: none;
  }